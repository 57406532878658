// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// FontAwesome
$fa-font-path: "../webfonts";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datepicker
@import '~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';


// AdminLTE
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

// Theme overrides
@import "theme-overrides";
@import "tables-config";

// Sweetalert2
@import "~sweetalert2/dist/sweetalert2.css";

// Main
@import "select2";
@import "daterangepicker";
@import "../css/app.css";

