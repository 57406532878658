// Admin lite template overrides

.nav-treeview {
    & > {
        .nav-item {
            & > .nav-link {
                padding-left: 1.7rem;
                padding-right: 1.7rem;
            }
        }
    }
}

.table-responsive>.table-bordered {
    border-top: 1px solid #dee2e6;
}

.card-header .card-title {
    margin-bottom: 0;
}

.bootstrap-datetimepicker-widget {
    width: 280px !important;
}
