.table th,
.table td {
    //word-break: break-word;
}

.table th {
    &.has-sort {
        padding-right: 25px;
        position: relative;

        i {
            position: absolute;
            top: 17px;
            right: 8px;
            cursor: pointer;
        }
    }
}

.table thead th {
    vertical-align: top;
}

.full-actions {
    .btn {
        width: 100%;
    }
    
    .action-btn {
        display: block;
        
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}