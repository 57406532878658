[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active, [class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:focus, [class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:hover {
    background-color: rgb(175 185 187 / 90%) !important;
}

.select2-container {
    display: block!important;
}

.select2-search__field {
    border: none!important;
}

.select2-selection__choice__display {
    color: #000!important;
    margin-left: 2px;
}

.select2-selection {
    height: 36px!important;
}

.sidebar-title{
    color: #c2c7d0 !important;
}
.was-validated select.form-control:invalid, select.form-control.is-invalid{
    background-position: right 18px center !important
}
